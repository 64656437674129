import { auth } from "./firebaseConfig";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { useEffect, useState } from "react";

export default function CurrentAuthUser() {
    const [user, setUser] = useState(null)
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            //console.log("Current auth user:", user)
            window.user = user;
            //console.log("App auth:", auth)
            window.auth = auth;
            setUser(user)
        });
    }, []);

    function logout(){
        signOut(auth);
    }
    if(!user){
        return <></>
    }
    return (
        <div>
            <pre style={{ textAlign: 'left' }}>{JSON.stringify(user, null, 2)}</pre>
            <button onClick={logout}>Logout</button>
        </div>
    )
}