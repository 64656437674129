import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDX5OJW-YOG20ECtPUYnhbub9ZvkZCoJqA",
    authDomain: "turnkey-world-324811.firebaseapp.com",
    databaseURL: "https://turnkey-world-324811-default-rtdb.firebaseio.com",
    projectId: "turnkey-world-324811",
    storageBucket: "turnkey-world-324811.appspot.com",
    messagingSenderId: "463139979510",
    appId: "1:463139979510:web:64c6f7e470e179885bacd7",
    measurementId: "G-GQTQC808NF"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);


export default firebaseConfig;
export { app, analytics, db, auth }