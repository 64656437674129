import * as firebaseui from 'firebaseui';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebaseui/dist/firebaseui.css';
import uiConfig from './uiConfig';
import { useEffect, useRef } from 'react';
import firebaseConfig from "./firebaseConfig";
import CurrentAuthUser from './CurrentAuthUser';


export default function AuthPage() {
    const mainDiv = useRef(null);
    const recaptchaContainer = useRef(null);
    useEffect(() => {
        firebase.initializeApp(firebaseConfig)
        var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
        if (mainDiv.current && !mainDiv.current.getAttribute('started')) {
            ui.start(mainDiv.current, uiConfig);
            mainDiv.current.setAttribute('started', 'true');
        }
    }, [])


    return (<div style={{
        margin: "auto",
        marginTop: "50vh",
        transform: "translateY(-50%)"
    }}>
        <div ref={mainDiv}></div>
        <div ref={recaptchaContainer}></div>
        <CurrentAuthUser />
    </div>)
}