import AuthPage from "./firebase/AuthPage";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

function Layout(props) {
  return <div className="App">
    <header className="App-header">
      {props.children}
    </header>
  </div>
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout><AuthPage /></Layout>} />
      </Routes>
    </Router>
  );
}

export default App;
