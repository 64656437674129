import actionCodeSettings from './actionCodeSettings';
import settings from '../constants/settings';

const uiConfig = {
    signInSuccessUrl: settings.signInSuccessURL,
    //queryParameterForSignInSuccessUrl:,
    signInFlow: 'popup',
    signInOptions: [
        {
            provider: 'google.com',
            recaptchaParameters: {
                type: 'image',
                size: 'invisible',
                badge: 'bottomleft'
            }
        },
        {
            provider: 'phone',
            recaptchaParameters: {
                type: 'image', // 'audio'
                size: 'normal', // 'invisible' or 'compact'
                badge: 'bottomleft' //' bottomright' or 'inline' applies to invisible.
            },
            defaultCountry: 'AE'
        },
        {
            provider: 'password',
            emailLinkSignIn: actionCodeSettings,
            forceSameDevice: false,
            signInMethod: 'emailLink',
            recaptchaParameters: {
                type: 'image',
                size: 'invisible',
                badge: 'bottomleft'
            }
        },

        //firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        //firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        //firebase.auth.GithubAuthProvider.PROVIDER_ID,
        //firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        //firebase.auth.AnonymousAuthProvider.PROVIDER_ID
    ],
    // tosUrl and privacyPolicyUrl accept either url string or a callback
    // function.
    // Terms of service url/callback.
    tosUrl: settings.termsOfServiceURL,
    // Privacy policy url/callback.
    privacyPolicyUrl: settings.privacyPolicyURL
};

export default uiConfig;